import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authState from './authReducer';
import globalState from './globalReducer';
import categoriesState from './categoriesReducer';
import consultationsState from './consultationsReducer';
import activityState from './activityReducer';
import additionalDataState from './additionalDataReducer';
import commentsState from './commentsReducer';
import currentUserState from './currentUserReducer';
import reportsState from './reportsReducer';
import paymentState from './paymentReducer';
import suggestionState from './suggestionReducer';
import nuggetsState from './nuggetsReducer';
import lorealStatisticState from './lorealStatisticReducer';
import sponsorStatisticState from "./sponsorStatisticReducer";

export default history => combineReducers({
  router: connectRouter(history),
  authState,
  globalState,
  categoriesState,
  consultationsState,
  activityState,
  additionalDataState,
  commentsState,
  currentUserState,
  reportsState,
  paymentState,
  suggestionState,
  nuggetsState,
  lorealStatisticState,
  sponsorStatisticState,
});
