import { takeEvery, put, call  } from 'redux-saga/effects';
import { SPONSOR_STATISTIC_ACTIONS } from '../constants';
import { sponsorStatisticActions } from '../actions';
import { processRequest } from '../services/Api';

function* handleSetStatistic(action) {
  const {payload} = action || {};
  const {requestData} = payload || {};
  const {sponsor_id, statistic_type} = requestData || {};
  try {
    const requestPayload = {
      sponsor_id,
      statistic_type,
    };
    const {data} = yield call(processRequest, `/statistics`, 'POST', requestPayload);

    if(data.message) {
      yield put(sponsorStatisticActions.setStatisticSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(sponsorStatisticActions.setStatisticError(e));
  }
}

export function* watchSponsorStatisticSagas() {
  yield takeEvery(SPONSOR_STATISTIC_ACTIONS.SET_SPONSOR_STATISTIC, handleSetStatistic);
};