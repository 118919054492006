import { SPONSOR_STATISTIC_ACTIONS, INITIAL_STATE } from '../constants';

export default function sponsorStatisticReducer(state = INITIAL_STATE.SPONSOR_STATISTIC_STATE, action) {
  const {error} = action.payload || {};

  switch(action.type) {
    case SPONSOR_STATISTIC_ACTIONS.SET_SPONSOR_STATISTIC_ERROR: {
      return {
        ...state,
        error
      };
    }
    default:
      return state;
  }
}