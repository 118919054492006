import React, { Component } from 'react';
import _ from 'lodash';
import { sortCategoriesAlphabetically, getLetters } from '../../../services/Helpers';
import { DashboardSearch, Categories, SearchResultCategories } from '../../';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.handleSearchCategories = _.debounce(props.categoriesActions.getCategories, 200)
    this.state = {
      selectedCategory: null,
      searchValue: ''
    };
  }

  componentDidMount() {
    const { categoriesActions: { getCategories, clearCategory }, setSponsorStatistic } = this.props;

    getCategories();
    clearCategory();
    setSponsorStatistic({sponsor_id: 14, statistic_type: 2});
  }

  setInputRef = ref => {
    this.searchInput = ref;
  }

  focus = () => {
    this.searchInput.focus();
  }

  clearSearchValue = () => {
    this.setState(() => ({ searchValue: '' }), () => {
      this.handleSearchCategories();
    });
  }

  onSearchChange = event => {
    const searchValue = event.target.value;

    this.setState(() => ({ searchValue,selectedCategory: null }), () => {
      this.handleSearchCategories(searchValue);
    });
  }

  onCategoryClick = selectedCategory => {
    this.setState({ selectedCategory });
  }

  onDeleteCategory = () => {
    const { selectedCategory } = this.state;
    const { globalActions, categories } = this.props;

    globalActions.toggleDeleteCategoryModal(true, selectedCategory || categories[0]);
  }

  onEditCategory = () => {
    const { selectedCategory } = this.state;
    const { globalActions, categories } = this.props;

    globalActions.toggleEditCategoryModal(true, selectedCategory || categories[0]);
  }

  onDeleteConsultation = (consultation_id, category_id) => {
    const consultation = { id: consultation_id, category_id };
    const { globalActions } = this.props;

    globalActions.toggleDeleteConsultationModal(true, consultation);
  }

  render() {
    const { categories, user } = this.props;
    const { searchValue, selectedCategory } = this.state;
    const onIconClick = searchValue ? this.clearSearchValue : this.focus;
    const sortCategories = categories.sort((category_a, category_b) => category_a.title.localeCompare(category_b.title));
    const sortedCategories = sortCategoriesAlphabetically(sortCategories);
    const letters = getLetters(sortedCategories);

    return (
      <main>
        <DashboardSearch
          onBlur={ this.onBlur }
          onFocus={ this.onFocus }
          setInputRef={ this.setInputRef }
          onIconClick={ onIconClick }
          onChange={ this.onSearchChange }
          searchValue={ searchValue }
        />
        { searchValue === '' &&
          <Categories
            categories={ sortedCategories }
            letters={ letters }
            user={user}
          />
        }
        { searchValue !== '' &&
          <SearchResultCategories
            categories={ sortedCategories }
            smallCategories={ sortCategories }
            letters={ letters }
            onCategoryClick={ this.onCategoryClick }
            selectedCategory={ selectedCategory || categories[0] }
            user={ user }
            onDeleteCategory={ this.onDeleteCategory }
            onEditCategory={ this.onEditCategory }
            onDeleteConsultation={ this.onDeleteConsultation }
          />
        }
      </main>
    );
  }
}

export default Dashboard;