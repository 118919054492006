import React, {useState} from 'react';
import {NoAdvert} from "./NoAdvert";
import {IMAGE_URL} from '../../../constants';
import BackArrow from '../../../imgs/back-arrow.svg';
import gifPlaceholder from '../../../imgs/gifPlaceholder.jpg';

const LOREAL_IDS = {
  36: 'Acne',
  234: 'Acne',
  40: 'Actinic Keratosis',
  33: 'Atopic Eczema',
  34: 'Contact Dermatitis',
  162: 'Psoriasis',
  39: 'Seborrhoeic Dermatitis',
};

const ConsultationHeader = ({
  consultation,
  goBack,
  user,
  onSponsorClick,
  sponsor,
  setStatistic,
  handleCopy,
}) => {
  const [loading, setLoading] = useState(true);

  const isGif = consultation?.sponsor_logo_url.includes('.gif') && consultation.id === 288;
  const isMob = window.innerWidth < 768;
  const linkView = () => {
    return LOREAL_IDS[consultation.id] ? (
      <p>{`To learn more about ${LOREAL_IDS[consultation.id]} click on the image:`}</p>
    ) : null;
  };
  const handleStatistic = () => {
    setStatistic({
      consultation_id: consultation.id,
      statistic_type: 0,
      button_name: LOREAL_IDS[consultation.id] || consultation.title,
    });
  };
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <div className="navigation-back sponsor">
      <button className='btn' onClick={ goBack }>
        <div className="back-container">
          <div className="back-arrow">
            <img src={ BackArrow } alt="Back arrow"/>
          </div>
          <div className="back-text">
            <p className="blue">{ consultation.category.title }</p>
          </div>
        </div>
      </button>
      { consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
        <a
          href={ `${ consultation.sponsor_url ? consultation.sponsor_url : '' }` }
          target='_blank'
          rel="noopener noreferrer"
          onClick={ handleStatistic }
        >
          <div className="navigation-sponsor">
            {/* <div className='sponsor-title'>
              <p>{ 'Kindly supported by: ' }</p>
            </div> */}
            {linkView()}
            <div className='support-logo'>
              {isGif ? (
                <>
                  {loading && (
                    <img src={ gifPlaceholder } alt="support logo" />
                  )}
                  <img
                    src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` }
                    alt="support logo"
                    onLoad={onLoad}
                    style={{visibility: loading ? 'hidden' : 'visible'}}
                  />
                </>
              ) : (
                <img src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` } alt="support logo" />
              )}
            </div>
          </div>
        </a>
      }
      { (sponsor && !consultation.is_sponsored && user.user_type !== 'practice_nurse') &&
        <a
          href={ `${ sponsor.url ? sponsor.url : '' }` }
          target='_blank'
          rel="noopener noreferrer"
          onClick={ onSponsorClick }
          // onClick={ handleStatistic }
        >
          <div className="navigation-sponsor">
            <div className='support-logo'>
              <img src={ `${ IMAGE_URL }${ sponsor.sponsor_logo_url }` } alt="support logo" />
            </div>
            {/* <div className='sponsor-title'>
              <p>{ 'Supported by: ' }</p>
            </div> */}
          </div>
        </a>
      }
      {(!sponsor && !consultation.is_sponsored && user.user_type !== 'practice_nurse') && (
        <NoAdvert
          hide={isMob}
          onClick={onSponsorClick}
        />
      )}
      <button
        className='btn small outlined btn-add-category'
        onClick={handleCopy}
        type='button'
      >
        Copy to clipboard
      </button>
    </div>
  )
};

export default ConsultationHeader;